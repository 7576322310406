const ActionTypes = {
    LOAD_SESSION: 'LOAD_SESSION',
    SET_SESSION: 'SET_SESSION',
    RECEIVE_PERSONAS: 'RECEIVE_PERSONAS',
    PUT_PERSONA: 'PUT_PERSONA',
    REMOVE_PERSONA: 'REMOVE_PERSONA',
    GET_REACTIONS: 'GET_REACTIONS',
    RECEIVE_REACTIONS: 'RECEIVE_REACTIONS',
    PUT_REACTION: 'PUT_REACTION',
    REMOVE_REACTION: 'REMOVE_REACTION',
    RECEIVE_VANITY: 'RECEIVE_VANITY',
    RECEIVE_GUILD: 'RECEIVE_GUILD',
    GET_EMOJIS: 'GET_EMOJIS',
    RECEIVE_EMOJIS: 'RECEIVE_EMOJIS'
}

export default ActionTypes;